<template>
  <div>
    <div class="d-flex justify-content-between mb-1">
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        v-b-toggle.collapse-1
        variant="outline-primary"
      >
        {{ t('Filters') }}
      </b-button>
      <b-button
        variant="primary"
        @click="redirectToCreate"
      >
        {{ t('Create Company') }}
      </b-button>
    </div>
    <b-collapse
      id="collapse-1"
      class="mt-2"
    >
      <company-filter
        :filter="form"
        @searchData="recieveData"
      />
    </b-collapse>
    <b-card>
      <b-table
        sticky-header
        responsive="true"
        :items="items.data"
        :fields="companyFields"
        :per-page="items.per_page"
        :sort-by.sync="form.sortBy"
        :sort-direction="form.sortOrder"
        @row-contextmenu="contextmenu"
        @sort-changed="sortChanged"
      >
        <template #cell(company_id)="data">
          {{ data.item.company_id }}
        </template>
        <template #cell(company_name)="data">
          {{ data.item.company_name }}
        </template>
        <template #cell(company_email)="data">
          {{ data.item.company_email }}
        </template>
        <template #cell(owner_name)="data">
          {{ data.item.company_owner_name }}
        </template>
        <template #cell(city_name)="data">
          {{ data.item.city_name }}
        </template>
        <template
          v-if="items.total == 0"
          #table-caption
        >
          <div
            class="text-center"
          >
            {{ t('No Record Found') }}
          </div>
        </template>
      </b-table>

      <b-row v-if="items.total">

        <b-col
          lg="4"
          class="py-2 d-flex"
        >
          <span class="pr-2 pt-10 align-self-center">
            {{ t('Total Entries') + ' ' + items.total }}
          </span>

          <b-form-group class="mb-0 align-self-center">
            <label class="d-inline-block text-sm-left mr-50">{{ t('Per page') }}</label>
            <b-form-select
              id="perPageSelect"
              v-model="form.size"
              size="sm"
              :options="tableFilter.pageOptions"
              class="w-70"
              @change="getCompanyList"
            />
          </b-form-group>

        </b-col>
        <b-col lg="8">
          <pagination
            :data="items"
            :limit="2"
            class="pt-1 justify-content-end"
            @pagination-change-page="getResults"
          />
        </b-col>

      </b-row>
    </b-card>
    <vue-context
      ref="companyContextMenu"
    >
      <li>
        <b-link
          v-for="data in menuData"
          :key="data.text"
          class="d-flex align-items-center"
          @click="optionClicked(data.text)"
        >
          <feather-icon
            :icon="data.icon"
            size="16"
          />
          <span class="ml-75">{{ data.text }}</span>
        </b-link>
      </li>
    </vue-context>
  </div>
</template>

<script>
import {
  BTable, BCard, BButton, VBToggle, BCollapse, BLink, BRow, BCol, BFormGroup, BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VueContext from 'vue-context'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import { useUtils } from '@core/libs/i18n'
import CompanyFilter from './CompanyFilter.vue'
import store from '@/store'
import constants from '@/constants'
import { tableFilter, showLoader, hideLoader } from '@/utils/common'

export default {
  name: 'CreateCompany',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BLink,
    BCollapse,
    CompanyFilter,
    BFormGroup,
    BFormSelect,
    VueContext,
    BTable,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  setup() {
    const { t } = useUtils()
    return { t }
  },
  data() {
    return {
      tableFilter,
      items: {},
      values: {},
      form: {
        params: {
          owner_name: '',
          city: '',
          email: '',
          name: '',
        },
        size: 10,
        totalElements: '',
        totalPages: '',
        sortBy: 'company_id',
        sortOrder: 'desc',
      },
    }
  },
  computed: {
    menuData() {
      return [
        { icon: 'EditIcon', text: this.$i18n.t('Edit Company') },
      ]
    },
    companyFields() {
      return [
        { key: 'company_id', label: this.$i18n.t('Id'), sortable: true },
        { key: 'company_name', label: this.$i18n.t('Name'), sortable: true },
        { key: 'company_email', label: this.$i18n.t('Email'), sortable: true },
        { key: 'owner_name', label: this.$i18n.t('Owner Name'), sortable: true },
        { key: 'city_name', label: this.$i18n.t('City'), sortable: true },
      ]
    },
  },
  created() {
    // this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true)
  },
  mounted() {
    this.getCompanyList()
  },
  methods: {
    async getCompanyList() {
      showLoader()
      await store.dispatch('companyStore/getCompanyList', this.form)
        .then(response => {
          if (response.data.code === '200') {
            this.items = response.data.data
          }
        }).catch(e => {
        // eslint-disable-next-line eqeqeq
          if (e.msg == 'Unauthenticated.') {
            this.$router.back()
          }
        })
      hideLoader()
    },
    sortChanged(val) {
      this.form.sortBy = val.sortBy
      this.form.sortOrder = val.sortDesc ? 'desc' : 'asc'
      this.getCompanyList()
    },
    getResults(page = 1) {
      // eslint-disable-next-line no-undef
      axios.post(`${constants.WEB_API_PREFIX}/master-admin/company/list?page=${page}`, this.form, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
        .then(response => {
          this.items = response.data.data
        })
    },
    recieveData(val) {
      this.form = val
      this.getCompanyList()
    },
    redirectToCreate() {
      this.$router.push('/master-company-management/company/create-company')
    },
    contextmenu(item, index, event) {
      event.preventDefault()
      this.values = item
      this.$refs.companyContextMenu.open(event, item)
    },
    optionClicked(text) {
      // eslint-disable-next-line eqeqeq
      if (text == 'Edit Company') {
        this.$root.$router.push(`/master-company-management/company/edit-company/${this.values.encode_company_id}`)
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-context.scss';
</style>
